const initializeIovation = (resolve, reject) => {
  window.io_global_object_name = 'BV_IOVATION';
  window.BV_IOVATION = window.BV_IOVATION || {
    enable_flash: false,
    bb_callback(bb, complete) {
      if (complete) {
        resolve(bb);
      }
    },
    loader: {
      uri_hook: '/iojs',
      version: 'general5',
      subkey: window.BVVars.iovationSubkey,
      trace_handler() {
        // TODO add airbrake logger if possible / trigger an event to be caught by GA
        reject();
      },
    },
  };
};

const promise = new Promise((resolve, reject) => {
  initializeIovation(resolve, reject);
  // eslint-disable-next-line global-require
  require('src/utils/iovation/loader_only.js');
});

export default () => (
  promise.catch(() => null)
);
